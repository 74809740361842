
<template>

  <div class="box">
    <H1 style="color: #7ecb57">暂未开放注册！请联系相关人员解答</H1>
  </div>

</template>

<script>
export default{
  name:"register",
  data(){
    return{

    }
  }
}
</script>

<style>
</style>